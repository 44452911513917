import { render, staticRenderFns } from "./Goals.vue?vue&type=template&id=333e7ca4&scoped=true"
import script from "./Goals.vue?vue&type=script&lang=js"
export * from "./Goals.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "333e7ca4",
  null
  
)

export default component.exports