<template>
  <v-container>
    <div class="pa-2">
      <h1 class="primary--text">Objectifs</h1>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Année
            </th>
            <th class="text-left">
              Objectif
            </th>
            <th class="text-left">
              Actions
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item of objectifs"
              :key="item.name"
          >
            <td>{{ extractYear(item.key) }}</td>
            <td>{{ item.value }}</td>
            <td>
              <v-btn icon color="red" @click="deleteGoal(item.id)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field type="number" label="Année" min="2000" step="1" v-model="year"></v-text-field>
            </td>
            <td>
              <v-text-field type="number" label="Objectif" v-model="goal"></v-text-field>
            </td>
            <td>
              <v-btn color="primary" @click="createGoal">Créer l'objectif</v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-container>
</template>

<script>

export default {
  name: "Objectif",
  async mounted() {
    if (this.$store.getters["config/configs"].length === 0) {
      await this.$store.dispatch("config/fetchConfigs");
    }
  },
  computed: {
    objectifs() {
      return this.$store.getters["config/objectifs"];
    }
  },
  data() {
    return {
      year: null,
      goal: null
    };
  },
  methods: {
    /**
     *
     * @param {string} value
     */
    extractYear(value) {
      const regex = /GOAL_(\d+)/i;
      let match = value.match(regex);
      console.log(match);
      return match !== null ? match[1] : "Valeur incorrecte";
    },
    async createGoal() {
      if (this.year !== null && this.goal !== null) {
        await this.$store.dispatch("config/postConfig", {key: `GOAL_${this.year}`, value: this.goal});
        await this.$store.dispatch("annonce/annonceSuccess", "L'objectif a été défini.");
      } else {
        await this.$store.dispatch("annonce/annonceWarning", "Les champs ne sont pas bien rempli.");
      }
      this.resetFields();
    },
    async deleteGoal(id) {
      await this.$store.dispatch("config/deleteConfig", {id: id});
      await this.$store.dispatch("annonce/annonceSuccess", "L'objectif a été supprimé.");
      this.resetFields();
    },
    resetFields() {
      this.year = null;
      this.goal = null;
    }
  }
}
</script>

<style scoped>

</style>